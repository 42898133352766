import { Component, OnDestroy, ViewChild } from '@angular/core'
import { PlugService } from 'src/app/services/plug.service'
import { FormGroup, Validators, FormArray, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { Plug } from 'src/app/classes/plug.class'
import { EchargerService } from 'src/app/services/echarger.service'
import { UtilityService } from '../../../services/utility.service'
import { StationPlug } from 'src/app/interfaces/station-plug'
import { ActivatedRoute } from '@angular/router'
import { Echarger } from 'src/app/classes/echarger.class'
import { Vendor } from 'src/app/classes/vendor.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { EchargerProtocol } from 'src/app/enums/echarger-protocol.enum'
import { OCPPLegalType } from 'src/app/enums/ocpp-legal-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { VendorService } from 'src/app/services/vendor.service'
import { TransactionService } from 'src/app/services/transaction.service'

@Component({
  selector: 'app-create-echarger',
  templateUrl: './create-echarger.component.html',
  styleUrls: ['./create-echarger.component.scss']
})
export class CreateEchargerComponent
  extends FormCanDeactivate
  implements OnDestroy {

  private connectionInterval: NodeJS.Timer

  @ViewChild(ModalComponent) leaveModal: ModalComponent
  public createEchargerForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    name: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    notes: new FormControl(''),
    ipv4Address: new FormControl(
      '',
      [Validators.required, Validators.pattern(/^(?:\d{1,3}\.){3}\d{1,3}$/)]
    ),
    plugs: new FormArray([new FormGroup({
      plug: new FormControl(null, Validators.required),
      number: new FormControl(1, [Validators.required, Validators.min(0)]),
      phases: new FormControl(0, [Validators.required, Validators.min(1)]),
      power: new FormControl(0, [Validators.required, Validators.min(1)]),
      publicKey: new FormControl(undefined)
    })]),
    description: new FormControl(undefined),
    protocol: new FormGroup({
      name: new FormControl(undefined, Validators.required),
      version: new FormControl(undefined)
    }),
    vendor: new FormControl(undefined, Validators.required),
    active: new FormControl(true, [Validators.required]),
    prechargeLimit: new FormControl(
      undefined,
      [Validators.min(1), Validators.max(120)]
    ),
    chargingCapacity: new FormControl(
      undefined,
      [Validators.min(1), Validators.max(65535), Validators.required]
    ),
    dischargingCapacity: new FormControl(
      undefined,
      [Validators.min(0), Validators.max(65535), Validators.required]
    ),
  })
  private subscriptions: Subscription[] = []
  public plugsList: Plug[] = []
  public plugsToSave: StationPlug[] = []
  public PROTOCOL = EchargerProtocol
  public protocols = Object.values(this.PROTOCOL)
  public LEGAL_TYPES = OCPPLegalType
  public legalTypes = Object.values(this.LEGAL_TYPES)
  public vendorList: Vendor[] = []
  public updateMode
  public loading = false
  public alert: Alert

  constructor (
    private _translate: TranslateService,
    private _transaction: TransactionService,
    private _plug: PlugService,
    private _echarger: EchargerService,
    public _utility: UtilityService,
    public _vendor: VendorService,
    private route: ActivatedRoute
  ) {
    super()
    this.init()
  }

  public async init () {
    this.loading = true
    await this.getPlugs()
    await this.getVendors()
    this.setUpdatePage()
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
    clearInterval(this.connectionInterval)
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe((url) => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }

      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true

    try {
      const value = this.createEchargerForm.getRawValue()
      console.log(this.createEchargerForm.getRawValue())
      const echarger = new Echarger({
        ...value,
        plugs: value.plugs.map(p => ({ ...p, number: p.number ? 1 : 0 }))
      })

      const obj = {
        ...echarger,
        protocol: echarger.protocol.name
      }

      obj.plugs.forEach((plug) =>  !plug.publicKey && delete plug.publicKey)

      const response = (this.updateMode)
        ? await this._echarger.updateEcharger(obj).toPromise()
        : await this._echarger.createCharge(obj).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.ECHARGER_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })

      if (!this.updateMode) {
        this.createEchargerForm.reset()
      }

      this.createEchargerForm.markAsPristine()
      this.loading = false

      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  private async getPlugs () {
    try {
      const response = await this._plug
        .searchPlug({ active: true }).toPromise()

      if (response.valid) {
        this.plugsList = response.data.data
          .sort((p1, p2) => p1.dataLong.localeCompare(p2.dataLong))
      } else {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async getVendors () {
    try {
      const response = await this._vendor.getAllVendors().toPromise()

      if (response.valid) {
        this.vendorList = response.data.data
          .sort((v1, v2) => v1.name.localeCompare(v2.name))
      } else {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public addPlugRow (): void {
    const plugs = (this.createEchargerForm.get('plugs') as FormArray)

    if (plugs.controls.length < 8) {
      plugs.push(new FormGroup({
        plug: new FormControl(null, Validators.required),
        number: new FormControl(0, [Validators.required, Validators.min(0)]),
        phases: new FormControl(0, [Validators.required, Validators.min(1)]),
        power: new FormControl(0, [Validators.required, Validators.min(1)]),
        publicKey: new FormControl(undefined)
      }))
    }
  }

  public removePlugRow (index: number): void {
    (this.createEchargerForm.get('plugs') as FormArray).removeAt(index)
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._echarger.getEcharger(_id).toPromise()

      if (response.valid) {
        const echarge = response.data

        let plugs = []
        if (echarge.plugs) {
          // Remove the first plug row if there is no plug data for the station
          // Or remove the overhead rows on reset (added ones need to be
          // removed)
          const plugCount = (this.createEchargerForm.get('plugs') as FormArray)
            .controls.length

          const echargePlugs = echarge.plugs.length

          if (echargePlugs < plugCount) {
            for (let i = echargePlugs; i < plugCount; i++) {
              this.removePlugRow(plugCount - 1 - (i - echargePlugs))
            }

          // case: not reset but normal load or reset with removed rows
          } else if (echargePlugs !== plugCount || !echarge.plugs) {
            for (let j = 0; j < echargePlugs - plugCount; j++) {
              this.addPlugRow()
            }
          }

          plugs = echarge.plugs.map(plug => ({ ...plug, plug: plug.plug._id }))
        }

        if (!echarge.code) { echarge.code = '' }
        if (!echarge.ipv4Address) { echarge.ipv4Address = '' }
        if (!echarge.description) { echarge.description = '' }

        if (echarge.protocol.name === 'OCPP') {
          this.protocolChanged('OCPP')
        }

        this.checkActiveCharges(echarge._id)
        this.connectionInterval = setInterval(() =>
          this.checkActiveCharges(echarge._id), 10000)

        return this.createEchargerForm.patchValue({
          ...echarge,
          plugs,
          vendor: `${echarge.vendor._id}`
        })
      } else {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public async checkActiveCharges (_id: string) {
    try {
      const responseConnection = await this._transaction
        .getEchargersConnection(_id).toPromise()
      if (responseConnection.data.transactions.length) {
        this.createEchargerForm.disable()
        this.createEchargerForm.get('notes').enable()
      } else {
        this.createEchargerForm.enable()
      }
    } catch (error) {
      // do nothing
    }
  }

  public setAllPlugs (value: boolean): void {
    (this.createEchargerForm.controls.plugs as FormArray)
      .controls.forEach((plug) => plug.setValue(value))
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })

    return this.alert.present()
  }

  public async updateNotes () {
    try {
      const { _id, notes } = this.createEchargerForm.getRawValue()

      const echargerResponse = await this._echarger
        .getEcharger(_id).toPromise()

      if (!echargerResponse.data) {
        throw new Error(echargerResponse.message)
      }

      const echarger = new Echarger({
        ...echargerResponse.data,
        notes,
        plugs: echargerResponse.data.plugs
          .map(p => ({ ...p, number: p.number ? 1 : 0 }))
      })

      await this._echarger
        .updateEcharger({ ...echarger, protocol: echarger.protocol.name })
        .toPromise()

      this.createEchargerForm.get('notes').markAsPristine()

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.ECHARGER_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })

      return this.alert.present()
    } catch (error) {
      this.loading = false
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  // here necessary and can not be used from service since addressValidation
  // also needs to be set can probably be removed
  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createEchargerForm]
  }

  public clearForm () {
    this.createEchargerForm.reset({
      plugs: [{
        number: 0,
        phases: 0,
        power: 0,
        publicKey: undefined
      }]
    })
    this.removePlugRow(3)
    this.removePlugRow(2)
    this.removePlugRow(1)
  }

  public protocolChanged (protocol: string) {
    if (protocol === this.PROTOCOL.OCPP) {
      this.createEchargerForm.addControl('legal', new FormControl(undefined, Validators.required))
    } else {
      this.createEchargerForm.removeControl('legal')
    }
    this.createEchargerForm.patchValue({ protocol: { version: '' } })
  }
}
